<template>
  <div class="userTable">

    <div class="filter-block">
      <div class="filter-field">
        <span class="title"> {{i18n('Basic.Account')}}</span>
        <input type="type" name="account" value="" v-model="searchForm.account" />
      </div>

      <div class="filter-field">
        <span class="title"> {{i18n('Basic.ChineseName')}} </span>
        <input type="type" name="name" value="" v-model="searchForm.name" />
      </div>

      <div class="filter-field">
        <span class="title"> Email </span>
        <input type="type" name="email" value="" v-model="searchForm.email" />
      </div>

      <div class="filter-btn-wrap">
        <a class="btn btn-blue mr-2" @click.prevent="getUser">{{i18n('Basic.Search')}}</a>
        <a class="btn btn-yellow" @click.prevent="restorePassword">{{i18n('Custom.ResetPassword')}}</a>
      </div>
    </div>

    <vue-bootstrap4-table
      class=""
      :rows="rows"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="func" slot-scope="props">
        <button
          class="btn btn-sm btn-yellow mr-2"
          data-toggle="modal"
          data-target="#editModal"
          @click.prevent="openModal(false, props.row)"
        >
          {{i18n('Basic.Search')}}
        </button>
      </template>
    </vue-bootstrap4-table>

    <!-- <EditModal /> -->

    <!-- Modal -->
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <form @submit.prevent="editUser" class="modal-content">
          <div class="modal-header">{{i18n('Custom.UserInformation')}}</div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="field">
                  <label class="field-name">{{i18n('Basic.Account')}}</label>
                  <input
                    id="title"
                    type="text"
                    name="title"
                    value=""
                    v-model="tempData.Account"
                    :readonly="!isNew"
                    required
                    minlength="8"
                    maxlength="8"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="field">
                  <label class="field-name">{{i18n('Basic.ChineseName')}}</label>
                  <input
                    type="type"
                    name="name"
                    value=""
                    v-model="tempData.Name"
                    required
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="field">
                  <label class="field-name">Email</label>
                  <input
                    type="email"
                    name="name"
                    value=""
                    v-model="tempData.Email"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="field">
                  <label class="field-name">{{i18n('Basic.Phone_Number')}}</label>
                  <input
                    type="type"
                    name="name"
                    value=""
                    v-model="tempData.PhoneNumber"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="field">
                  <label class="field-name">{{i18n('Basic.State')}}</label>
                  <select v-model="tempData.IsEnabled">
                    <option :value="true">{{i18n('Basic.Enable')}}</option>
                    <option :value="false">{{i18n('Basic.Off')}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="field">
                  <label class="field-name">>{{i18n('Custom.IsAd')}}</label>
                  <select v-model="tempData.IsActiveDirectory">
                    <option :value="true">{{i18n('Basic.Yes')}}</option>
                    <option :value="false">{{i18n('Basic.No')}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6" v-if="tempData.IsActiveDirectory">
                <div class="field">
                  <label class="field-name">{{i18n('Custom.AdDomain')}}</label>
                  <select v-model="tempData.Domain">
                    <option :value="null" disabled>{{i18n('Basic.PleaseSelect')}}</option>
                    <option
                      :value="ad.DomainId"
                      v-for="ad of adList"
                      :key="ad.DomainId"
                    >
                      {{ ad.ServerName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-warning"
              @click.prevent="originalPassword(tempData.Account)"
            >
              {{i18n('Custom.ResetPassword')}}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button type="submit" class="btn btn-blue">
              {{i18n('Basic.Save')}}</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Delete Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{i18n('Basic.Delete')}}</div>

          <div class="modal-body">
            <div class="alert-box">
              <div class="alert-icon">!</div>
              {{i18n('Custom.WhetherDeleteItem')}}
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteUser"
            >
              {{i18n('Basic.Delete')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBootstrap4Table from 'vue-bootstrap4-table';
// import EditModal from './EditModal'
// import { mapActions } from 'vuex';
import $ from 'jquery';

export default {
  components: {
    VueBootstrap4Table,
    // EditModal,
  },
  data() {
    return {
      screen: null, // loading遮罩
      rows: [],
      adList: [], // 全部 AD 資料
      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      classes: {
        table: 'table-light table-striped',
        // via: 'table-danger'
      },
      tempData: {
        IsEnabled: true,
        IsActiveDirectory: false,
      },
      searchForm: {},
      isNew: false,
    };
  },
  computed:{
     columns(){
      const col =[
        {
          label: this.i18n('Basic.Account'),
          name: 'Account',
          sort: true,
        },
        {
          label: this.i18n('Basic.Name'),
          name: 'Name',
          sort: true,
        },
        {
          label: 'Email',
          name: 'Email',
          sort: true,
        },
        {
          label: this.i18n('Basic.State'),
          name: 'Status',
          sort: true,
        },
        {
          label: this.i18n('Custom.Function'),
          name: 'func',
          slot_name: 'func',
        },
      ]
      return col;
     }
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getUser() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/user`;
      const params = { ...this.searchForm };
      this.$http.get(url, { params }).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          this.rows = response.data.Data.map((user) => ({
            ...user,
            Status: user.IsEnabled ? '已啟用' : '未啟用',
          }));
        }
        this.screen.close();
      })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    restorePassword() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/User/RestorePassword/All`;
      this.$http.post(url).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          this.$notify({
            title: '成功',
            message: response.data.Data,
            type: 'success',
            duration: 0,
          });
        }
        this.screen.close();
      })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    // 取得所有資料***************************************************************
    getAD() {
      const vm = this;
      const url = `${window.BaseUrl.api}/AD/domain`;

      vm.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.adList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    openModal(isNew, item) {
      //   console.log(isNew, item);
      if (!isNew) {
        this.tempData = {
          ...item,
        };
        this.isNew = false;
      } else {
        this.tempData = {
          IsEnabled: true,
          IsActiveDirectory: true,
          Domain: null,
        };
        this.isNew = true;
      }
    },
    editUser() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/user`;

      let httpMethod = 'post';
      const obj = {
        Account: this.tempData.Account,
        UserName: this.tempData.Name,
        Email: this.tempData.Email,
        PhoneNumber: this.tempData.PhoneNumber,
        IsEnabled: this.tempData.IsEnabled,
        IsActiveDirectory: this.tempData.IsActiveDirectory,
        Domain: this.tempData.Domain,
      };

      if (!this.isNew) {
        httpMethod = 'put';
        // obj = {
        //     ...this.tempData
        // }
      }

      this.$http[httpMethod](url, obj)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.$store.dispatch('alertMessageModule/updateMessage', {
              message: '成功編輯帳號',
              status: 'success',
            });
          }

          $('#editModal').modal('hide');
          // this.rows = response.data
          // if (response.data[0]?.Description) {
          //   this.$store.dispatch('alertMessageModule/updateMessage', {
          //     message: response.data[0].Code,
          //     status: 'danger',
          //   });
          // }
          this.getUser();
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    deleteUser() {
      const url = `${window.BaseUrl.api}/auth/user/${this.tempData.Account}`;

      this.updateLoading(true);
      this.$http
        .delete(url)
        .then((/* response */) => {
          // console.log(response)

          this.getUser();
          this.updateLoading(false);
          this.$store.dispatch('alertMessageModule/updateMessage', {
            message: '成功刪除帳號',
            status: 'success',
          });
        })
        .catch((/* error */) => {
          // console.log(error)
        });
    },
    originalPassword(account) {
      //  還原密碼
      const url = `${window.BaseUrl.api}/user/RestorePassword`;
      const obj = {
        account,
      };

      this.$http
        .put(url, obj)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.$store.dispatch('alertMessageModule/updateMessage', {
              message: '重設密碼成功',
              status: 'success',
            });
          }
        })
        .catch(() => {
          this.$notify({
            title: '失敗',
            message: '重設密碼失敗',
            type: 'error',
          });
        });

      $('#editModal').modal('hide');
    },
  },
  created() {
    this.getUser();
    this.getAD();
  },
};
</script>
